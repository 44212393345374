<template>
  <div>
    <form class="mb-3" @submit.prevent="resetForgotPassword">
      <p class="title">重設密碼</p>
      <!-- 新密碼 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="NewPassword"
          placeholder="新密碼"
          maxlength="40"
          v-model="resetData.newPassword"
        />
        <label for="NewPassword">新密碼</label>
      </div>
      <!-- 確認密碼 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="ConfirmPassword"
          placeholder="確認密碼"
          maxlength="40"
          v-model="resetData.confirmPassword"
        />
        <label for="ConfirmPassword">確認密碼</label>
      </div>
      <button class="form-btn" type="submit">確認</button>
    </form>
    <router-link
      class="d-block text-end"
      :to="identity === 'seller' ? '/sellerLogin' : '/customerLogin'"
      >返回登入頁面 >></router-link
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      resetData: {
        token: '',
        newPassword: '',
        confirmPassword: ''
      },
      identity: 'customer',
    }
  },
  created() {
    // 初始化
    this.initialization()
  },
  methods: {
    // 初始化
    initialization() {
      this.identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
      if (!this.$route.query.token) {
        alert('尚未取得系統配送的Token，請正常操作')
        this.goBack()
        return
      }
      this.resetData = {
        token: this.$route.query.token,
        newPassword: '',
        confirmPassword: ''
      }
    },
    // 重設密碼
    resetForgotPassword() {
      const vm = this
      const resetForgotPasswordApi = `${process.env.VUE_APP_API}/user/resetForgottenPassword`
      const data = new FormData()
      data.append('token', this.resetData.token)
      if (this.resetData.newPassword) {
        data.append('newPassword', this.resetData.newPassword)
      }
      if (this.resetData.confirmPassword) {
        data.append('confirmPassword', this.resetData.confirmPassword)
      }
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'post',
        async: true,
        url: resetForgotPasswordApi,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const token = res.data
            vm.$methods.setCookie('serverToken', token)
            vm.$router.push(vm.$methods.go('normal'))
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          if (err.responseJSON.message === 'newPassword不能為空') {
            vm.SweetAlert('other', '新密碼不能為空')
          } else if (err.responseJSON.message === 'confirmPassword不能為空') {
            vm.SweetAlert('other', '確認密碼不能為空')
          } else {
            vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          }
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 返回登入頁
    goBack() {
      if (this.identity === 'seller') this.$router.push('/sellerLogin')
      else this.$router.push('/customerLogin')
    },
  },
}
</script>